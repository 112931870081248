import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactPlayer from 'react-player/youtube';
import useLocales from '#/hooks/useLocales';
import {
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from '#/components/pages/LastWill/steps/content/stepsData';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '#/components/pages/PensionProvision/styles';
import { LastWillValues } from '#/types/lastWill';
import useAuth from '#/hooks/useAuth';

const Inheritors = () => {
  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const { user } = useAuth();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const isLivingPartnership = watch(
    'living_partnership' as keyof LastWillValues
  );

  const { translate } = useLocales();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const handleOnChange = (
    questionName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(questionName, event.target.value);
  };

  const handleDefaultValues = (question: string) =>
    watch?.(`${question}` as keyof LastWillValues);

  const civilStatusQuestionary = () => {
    const civilStatus = user?.civil_status || 'married';
    if (civilStatus === 'divorced') {
      return inheritorsDivorcedVersion;
    }
    if (civilStatus === 'single' || civilStatus === 'widowed') {
      return inheritorsSingleVersion;
    }
    return inheritorsMarriedVersion;
  };

  const tNonMQuestions = civilStatusQuestionary().map((question, i) => ({
    ...question,
    question: (civilStatus: string) => {
      if (civilStatus === 'divorced') {
        return translate(
          `lastWill.stepOne.questions.inheritorsDivorcedVersion.${i}.question`
        );
      }
      if (civilStatus === 'single' || civilStatus === 'widowed') {
        return translate(
          `lastWill.stepOne.questions.inheritorsSingleVersion.${i}.question`
        );
      }
      return translate(
        `lastWill.stepOne.questions.inheritorsMarriedVersion.${i}.question`
      );
    },
    answers: question?.answers?.map((answer, j) => ({
      ...answer,
      label: (civilStatus: string) => {
        if (civilStatus === 'divorced') {
          return translate(
            `lastWill.stepOne.questions.inheritorsDivorcedVersion.${i}.options.${j}.label`
          );
        }
        if (civilStatus === 'single' || civilStatus === 'widowed') {
          return translate(
            `lastWill.stepOne.questions.inheritorsSingleVersion.${i}.options.${j}.label`
          );
        }
        return translate(
          `lastWill.stepOne.questions.inheritorsMarriedVersion.${i}.options.${j}.label`
        );
      },
    })),
  }));

  const showChildren = (question: string) => {
    const value = watch(`${question}` as keyof LastWillValues);
    // const isHaveChildren = question === String('have_children');

    // if (isHaveChildren) {
    //   return true;
    // }
    return String(value) === 'yes';
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('lastWill.stepOne.introParagraph'))} */}
      {/* </Typography> */}
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('lastWill.stepOne.helperParagraph'))} */}
      {/* </Typography> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          url="https://youtu.be/G0NFweJSMuQ"
          playing
          light="/assets/images/youtubeThumbnail.webp"
          width={670}
        />
      </Box>
      <Stack spacing={5}>
        {tNonMQuestions.map((question, index) => (
          <div
            key={question.value}
            ref={(ref) => {
              refs.current[`${question.value}`] = ref;
            }}
          >
            <Stack spacing={2}>
              <Controller
                name={question.value}
                control={control}
                defaultValue={handleDefaultValues(question.value)}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <StyledRadioGroup
                    aria-label={question.value}
                    name={question.value}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      handleOnChange(question.value, e);
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                    >
                      {String(
                        question.question(user?.civil_status || 'married')
                      )}
                    </Typography>
                    <Stack
                      spacing={2}
                      direction={{
                        xs: 'column',
                        sm: 'row',
                      }}
                    >
                      {question?.answers &&
                        question?.answers?.map((option) => (
                          <StyledFormControlLabel
                            key={`step-${question.id}-${option.value}`}
                            value={option.value}
                            control={<StyledRadio />}
                            label={String(
                              option.label(user?.civil_status || 'married')
                            )}
                          />
                        ))}
                    </Stack>
                    {!!error && question.answers && (
                      <FormHelperText error={!!error} sx={{ mx: 0 }}>
                        {error && error?.message}
                      </FormHelperText>
                    )}
                  </StyledRadioGroup>
                )}
              />
              {showChildren(`${question.value}`) && question.children && (
                <div
                  ref={(ref) => {
                    refs.current[`${question.children.value}`] = ref;
                  }}
                >
                  {question.children.content(isLivingPartnership)}
                </div>
              )}
              {question.answers?.length === 0 && question.children && (
                <div
                  ref={(ref) => {
                    refs.current[`${question.children.value}`] = ref;
                  }}
                >
                  {question.children.content(isLivingPartnership)}
                </div>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
};

export default Inheritors;
