import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Iconify from '#/components/shared/ui/Iconify';
import { RHFSelect } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import RepresentativesForm from '#/components/pages/LivingWill/Steps/content/Representatives/RepresentativesForm';
import { useGetRepresentatives } from '#/api/representativeQueries';

type Props = {
  error?: string;
};

// const roles = [
//   { value: 'executor', label: 'Executer' },
//   { value: 'lawyer', label: 'Lawyer' },
//   { value: 'trustee', label: 'Trustee' },
//   { value: 'familyMember', label: 'Family Member' },
//   { value: 'friend', label: 'Friend' },
// ];

const Representative = ({ error }: Props) => {
  const [lastAdded, setLastAdded] = useState<string | null>(null);
  const [addMore, setAddMore] = useState(false);

  const { data: representatives } = useGetRepresentatives();

  const { resetField, watch, setValue } = useFormContext();

  const { translate } = useLocales();

  const handleAddMore = () => {
    setAddMore((prev) => !prev);
  };

  const selectLastAdded = () => {
    const primaryGoverner = watch('will_executor');
    const secondaryGoverner = watch('will_executor_2');
    if (lastAdded) {
      if (!primaryGoverner && !secondaryGoverner) {
        setValue('will_executor', lastAdded);
      } else if (!secondaryGoverner) {
        setValue('will_executor_2', lastAdded);
      } else setValue('will_executor_2', lastAdded);
    }
  };

  useEffect(() => {
    selectLastAdded();
    // eslint-disable-next-line
  }, [lastAdded]);

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {!addMore && (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          <Stack direction="column" gap={4}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <RHFSelect
                name="will_executor"
                label={String(translate('global.formLabels.executor'))}
                placeholder={String('Select representative')}
              >
                <MenuItem
                  onClick={() => {
                    resetField(`governancePerson.personId`);
                    resetField('representatives');
                    handleAddMore();
                  }}
                >
                  <Iconify icon="mdi:account-plus" color="text.secondary" />
                  <Typography
                    variant="caption"
                    sx={{
                      textTransform: 'none',
                    }}
                  >
                    {String(translate('lastWill.stepThree.addAnotherExecutor'))}
                  </Typography>
                </MenuItem>
                {representatives?.map((rep) => (
                  <MenuItem
                    key={rep.id}
                    value={rep.id}
                    disabled={watch('will_executor_2') === rep.id}
                  >
                    {rep?.first_name} {rep?.last_name}
                  </MenuItem>
                ))}
              </RHFSelect>
              {watch('will_executor') && (
                <Button
                  variant="outlined"
                  onClick={() => setValue('will_executor', null)}
                >
                  <Iconify
                    icon="tabler:playstation-x"
                    sx={{
                      cursor: 'pointer',
                    }}
                  />
                </Button>
              )}
            </Stack>

            <Stack spacing={2}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '5px',
                }}
              >
                {String(translate('lastWill.alternativeGoverner'))}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <RHFSelect
                  name="will_executor_2"
                  label={String(translate('global.representative'))}
                  placeholder={String('Select representative')}
                >
                  <MenuItem
                    onClick={() => {
                      resetField(`governancePerson.personId`);
                      resetField('representatives');
                      handleAddMore();
                    }}
                  >
                    <Iconify icon="mdi:account-plus" color="text.secondary" />
                    <Typography variant="caption">
                      {String(
                        translate('lastWill.stepThree.addAnotherExecutor')
                      )}
                    </Typography>
                  </MenuItem>
                  {representatives?.map((rep) => (
                    <MenuItem
                      key={rep.id}
                      value={rep.id}
                      disabled={watch('will_executor') === rep.id}
                    >
                      {rep?.first_name} {rep?.last_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
                {watch('will_executor_2') && (
                  <Button
                    variant="outlined"
                    onClick={() => setValue('will_executor_2', null)}
                  >
                    <Iconify
                      icon="tabler:playstation-x"
                      sx={{
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>

          {/* <Button */}
          {/*  variant="contained" */}
          {/*  size="small" */}
          {/*  color="secondary" */}
          {/*  onClick={() => { */}
          {/*    resetField(`governancePerson.personId`); */}
          {/*    resetField('representatives'); */}
          {/*    handleAddMore(); */}
          {/*  }} */}
          {/*  sx={{ */}
          {/*    width: 'content-fit', */}
          {/*  }} */}
          {/*  startIcon={ */}
          {/*    <Iconify */}
          {/*      icon={addMore ? 'mdi:content-save' : 'mdi:plus-circle'} */}
          {/*    /> */}
          {/*  } */}
          {/* > */}
          {/*  <Typography variant="caption"> */}
          {/*    {String(translate('lastWill.stepThree.addAnotherExecutor'))} */}
          {/*  </Typography> */}
          {/* </Button> */}
        </Stack>
      )}

      {addMore && (
        <RepresentativesForm
          handleAddMore={handleAddMore}
          handleLastAdded={setLastAdded}
        />
      )}
      {!!error && addMore && (
        <FormHelperText error={!!error} sx={{ mx: 0 }}>
          {error && error}
        </FormHelperText>
      )}

      {/* <RHFSelect name="executor_role" label={String(translate('global.role'))}> */}
      {/*  {roles.map((role) => ( */}
      {/*    <MenuItem key={role.value} value={role.value}> */}
      {/*      {String(translate(`global.roles.${role.value}`))} */}
      {/*    </MenuItem> */}
      {/*  ))} */}
      {/* </RHFSelect> */}
    </Box>
  );
};

export default Representative;
