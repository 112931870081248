import * as Yup from 'yup';

export const stepOneSchema = (civilStatus: string) => {
  if (civilStatus === 'married' || civilStatus === 'registered_partnership') {
    return Yup.object().shape({
      marriage_or_partnership_contract: Yup.string().required(
        'Wählen Sie eine Option'
      ),
      partner_or_spouse_first_name: Yup.string().required(
        'validations.user.first_name'
      ),
      partner_or_spouse_last_name: Yup.string().required(
        'validations.user.last_name'
      ),
      partner_or_spouse_date_of_birth: Yup.date()
        .required('validations.user.date_of_birth.required')
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'validations.user.date_of_birth.max'
        ),
      partner_property: Yup.string().required('Wählen Sie eine Option'),

      partner_property_address: Yup.object().when('partner_property', {
        is: 'yes',
        then: (schema) =>
          schema.shape({
            street: Yup.string().required('validations.adress.street'),
            house_number: Yup.string().required(
              'validations.adress.house_number'
            ),
            zip_code: Yup.string().required('validations.adress.zip_code'),
            city: Yup.string().required('validations.adress.city'),
            country: Yup.string().required('validations.adress.country'),
          }),
      }),
      have_children: Yup.string().required('Wählen Sie eine Option'),

      // children_no: Yup.string().when('have_children', {
      //   is: (value: string) => value === 'yes',
      //   then: () => Yup.number().required('Wählen Sie eine Option'),
      //   otherwise: (schema) => schema.notRequired(),
      // }),

      // children: Yup.array().when(['children_no', 'have_children'], {
      //   is: (children_no: number | string, have_children: string) =>
      //     Number(children_no) > 0,
      //   then: (schema) =>
      //     schema.of(childSchema).required('Please add at least one child'),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
    });
  }
  if (civilStatus === 'divorced') {
    return Yup.object().shape({
      have_children: Yup.string().required('validations.selectAnOption'),
      // children_no: Yup.string().when('have_children', {
      //   is: (value: string) => value === 'yes',
      //   then: () => Yup.string().required('Wählen Sie eine Option'),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
      // children: Yup.array().when(['children_no', 'have_children'], {
      //   is: (children_no: number | string, have_children: string) =>
      //     Number(children_no) > 0,
      //   then: (schema) =>
      //     schema.of(childSchema).required('Please add at least one child'),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
    });
  }

  return Yup.object().shape({
    living_partnership: Yup.string().required('Wählen Sie eine Option'),

    marriage_or_partnership_contract: Yup.string().required(
      'Wählen Sie eine Option'
    ),
    partner_property: Yup.string().required('Wählen Sie eine Option'),
    partner_property_address: Yup.object().when('partner_property', {
      is: 'yes',
      then: (schema) =>
        schema.shape({
          street: Yup.string().required('Street is required'),
          house_number: Yup.string().required('House number is required'),
          zip_code: Yup.string().required('Zip code is required'),
          city: Yup.string().required('City is required'),
          country: Yup.string().required('Country is required'),
        }),
    }),
    partner_or_spouse_first_name: Yup.string().when('living_partnership', {
      is: 'yes',
      then: () => Yup.string().required('validations.user.first_name'),
    }),
    partner_or_spouse_last_name: Yup.string().when('living_partnership', {
      is: 'yes',
      then: () => Yup.string().required('validations.user.last_name'),
    }),
    partner_or_spouse_date_of_birth: Yup.date().when('living_partnership', {
      is: 'yes',
      then: () =>
        Yup.date()
          .required('validations.user.date_of_birth.required')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'validations.user.date_of_birth.max'
          ),
      otherwise: (schema) => schema.notRequired(),
    }),

    have_children: Yup.string().required('Wählen Sie eine Option'),

    // benefiters: Yup.string().required('Please select an option'),

    // children_no: Yup.string().when('have_children', {
    //   is: (value: string) => value === 'yes',
    //   then: () => Yup.string().required('Wählen Sie eine Option'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),

    // children: Yup.array().when(['children_no', 'have_children'], {
    //   is: (children_no: number | string, have_children: string) =>
    //     Number(children_no) > 0,
    //   then: (schema) =>
    //     schema.of(childSchema).required('Please add at least one child'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
  });
};

export const stepTwoSchema = Yup.object().shape({
  permitted_law: Yup.string().required('Wählen Sie eine Option'),
  inheritanceGoods: Yup.string().required('Wählen Sie eine Option'),
});

export const stepThreeSchema = Yup.object().shape({
  will_executor: Yup.string().required('Wählen Sie eine Option'),
  will_executor_2: Yup.string().required('Wählen Sie eine Option'),
});

export const schemaValues = (
  dbFormValues?: any,
  // children?: number,
  activeStep?: number
) => ({
  living_partnership: dbFormValues?.living_partnership || '',
  have_children: dbFormValues?.have_children || '',
  benefiters: dbFormValues?.benefiters || '',
  beneficiaries: dbFormValues?.beneficiaries || [],
  marriage_or_partnership_contract:
    dbFormValues?.marriage_or_partnership_contract || '',
  divorce_proceeding: dbFormValues?.divorce_proceeding || '',
  permitted_law: dbFormValues?.permitted_law || '',
  assetOrProperty: dbFormValues?.assetOrProperty || '',
  inheritanceGoods: dbFormValues?.inheritanceGoods || '',
  partner_property: dbFormValues?.partner_property || '',
  partner_property_address: {
    street: dbFormValues?.partner_property_address?.street || '',
    house_number: dbFormValues?.partner_property_address?.house_number || '',
    zip_code: dbFormValues?.partner_property_address?.zip_code || '',
    city: dbFormValues?.partner_property_address?.city || '',
    country: dbFormValues?.partner_property_address?.country || 'CH',
  },
  partner_or_spouse_first_name:
    dbFormValues?.partner_or_spouse_first_name || '',
  partner_or_spouse_last_name: dbFormValues?.partner_or_spouse_last_name || '',
  partner_or_spouse_date_of_birth:
    dbFormValues?.partner_or_spouse_date_of_birth || null,

  children_no: '',
  children: dbFormValues?.children || [
    {
      first_name: '',
      last_name: '',
      date_of_birth: '',
      from_commonMarriage: '',
    },
  ],
  will_executor: dbFormValues?.will_executor || '',
  will_executor_2: dbFormValues?.will_executor_2 || '',
  executor_role: dbFormValues?.executor_role || '',
  step: activeStep || 0,
});
